import React from "react";
import "./SkillContainer.css";
import { Chart as ChartJS } from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { ChartData } from "../../Data.js";

const SkillContainer = () => {
  const data = {
    labels: ChartData.map((data) => data.label),
    datasets: [
      {
        data: ChartData.map((data) => data.value),
        backgroundColor: ChartData.map((data) => data.bgcolor),
        borderColor: ChartData.map((data) => data.borderColor),
        borderWidth: 1,
      },
    ],
  }
  const options = {
    plugins: {
      title: {
        display: true,
        text: "My Skills",
      },
      legend: {
        display: false,
      },
    },
  }


  return (
    <section className="skills">
      <h1 className="heading">My Weapons</h1>
      <div className="content">
        <div className="left">
          <Bar
            data={data}
            options={options}
          />
        </div>
        <div className="right">
          <ul>
            {ChartData.map((data, index) => (<li key={data.label + index}>
              <div className="preview" style={{backgroundColor: data.bgcolor, border: "1px solid "+ data.borderColor}}/>
                <div style={{color: data.borderColor}}>{data.label} - <span className="pink-bold">{data.value}%</span></div>
            </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default SkillContainer;
