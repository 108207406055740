const ChartData = [
  {
    label: "HTML5",
    value: 80,
    bgcolor: "rgba(244, 121, 51, 0.5)",
    borderColor: "rgba(244, 121, 51, 1)",
  },
  {
    label: "CSS3",
    value: 70,
    bgcolor: "rgba(0,143,213,.5)",
    borderColor: "rgba(0,143,213,1)",
  },
  {
    label: "SCSS",
    value: 50,
    bgcolor: "rgba(205,103,153,.5)",
    borderColor: "rgba(205,103,153,1)",
  },
  {
    label: "JS",
    value: 60,
    bgcolor: "rgba(75, 192, 192, .5)",
    borderColor: "rgba(75, 192, 192, 1)",
  },
  {
    label: "React.js",
    value: 50,
    bgcolor: "rgba(75, 192, 192, .5)",
    borderColor: "rgba(75, 192, 192, 1)",
  },
  {
    label: "Node/npm",
    value: 50,
    bgcolor: "rgba(63,135,63, .5)",
    borderColor: "rgba(63,135,63, 1)",
  },
  {
    label: "MongoDb",
    value: 30,
    bgcolor: "rgba(89,57,7,.5)",
    borderColor: "rgba(89,57,7,1)",
  },
];

export { ChartData };
