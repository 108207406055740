import {HomeContainer, ProjectsContainer, SkillContainer, ContactContainer, FooterContainer} from "./Container";

const App = () => {
  return (
    <>
    <HomeContainer/>
    <ProjectsContainer/>
    <SkillContainer />
    <ContactContainer />
    <FooterContainer/>
    </>
  );
}

export default App;
