import React from "react";
import "./ProjectsContainer.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import colorHex from "../../assets/color hex.jpg"

const ProjectsContainer = () => {
  let projectArray = [
    {
      title: "Animoe Bot",
      description:
        "Animoe is a telegram bot that gives information of animes and movies",
      link: "https://telegram.dog/animoe_bot",
      imageSrc: colorHex,
    },
    {
      title: "Color Hex",
      description:
        "Color Hex is a tool that generates 50 color hex codes with prieview",
      link: "https://pratyush1100.github.io/colour-hex/",
      imageSrc: colorHex,
    },
  ];

  return (
    <div className="projectsContainer">
      <Carousel 
      autoPlay={true}
      infiniteLoop={true}
      interval={8000}
      showThumbs={false}
      onClickItem={(i) => {
        window.location.href = projectArray[i].link
      }}>
      {projectArray.map((project,index) => (
                <div key={"hehe" + index} style={{cursor: "pointer"}}>
                    <img src={project.imageSrc} alt={project.title} /> 
                    <p className="legend">{project.title}</p>  
                </div>
      ))}
      </Carousel>
    </div>
  );
};

export default ProjectsContainer;
