import React from 'react'
import "./HomeContainer.css"
import profileImg from "../../assets/IMG_20220518_130746.jpg"
import overlayImage from "../../assets/Group 1.png"

const homeContainer = () => {
    return (
        <section className='homeContainer'>
            <img className="overlay" src={overlayImage} alt="overlay" />
            <div className="top">
                <div className="left">
                    <h5>Hi! 👋</h5>
                    <h1><span>I’m Pratyush</span>, a frontend developer from India. A passionate one in human-centred Websites.</h1>
                </div>
                <div className="right">
                    <div className="imagebox">
                        <img src={profileImg} alt="pic" />
                    </div>
                </div>
            </div>
            <div className="bottom">
            <p>Currently focusing on studies and <span className="pink-bold">web development</span> . Invite me to join your coders team at <a className="pink-bold" href="mailto:pratyush1100.dev@gmail.com">pratyush1100.dev@gmail.com</a></p>
            </div>
        </section>

    )
}

export default homeContainer