import React from 'react'
import "./ContactContainer.css"

const ContactContainer = () => {
  return (
    <section className="contactContainer">
        <h1>Let’s work together on your next product.</h1>
        <section class="contact-area offset" id="contact">
        <div class="contact-page">
            <div class="about">
                <div class="row">
                    <h4>About Us</h4>
                </div>
                <div class="row">
                    <span class="form-icon"><i class="fas fa-home"></i></span>
                    <span class="form-text">Patna, Bihar, India</span>
                </div>
                <div class="row">
                    <span class="form-icon"><i class="fas fa-phone"></i></span>
                    <span class="form-text">N/A</span>
                </div>
                <div class="row">
                    <span class="form-icon"><i class="fas fa-envelope-open-text"></i></span>
                    <span class="form-text">pratyush1100.dev@gmail.com</span>
                </div>
                <div class="row social">
                    <ul>
                        <li><a href="github.com/pratyush1100"><i class="fab fa-github"></i></a></li>
                        <li><a href="instagram.com/pratyush_1100"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="telegram.dog/pratyush1100"><i class="fab fa-telegram"></i></a></li>
                        <li><a href="telegram.dog/pratyush1100"><i class="fas fa-envelope-open-text"></i></a></li>
                    </ul>
                </div>
            </div>
            <form class="contact-form-firebase">
                <div class="row-form row-title">
                    <h4>Send Message</h4>
                </div>
                <div class="row-form flex-30">
                    <label for="name">Name</label>
                    <input type="text" id="name" name="name" class="name" required />
                </div>
                <div class="row-form flex-30">
                    <label for="email">Email id</label>
                    <input type="email" id="email" name="email" class="email" required />
                </div>
                <div class="row-form flex-30">
                    <label for="message">Your Message</label>
                    <textarea id="message" name="message" class="message" required></textarea>
                </div>
                <button type="submit" class="submit-btn">Submit</button>
            </form>
        </div>
    </section>
    </section>
  )
}

export default ContactContainer