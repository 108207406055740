import React from 'react'
import "./FooterContainer.css"

const FooterContainer = () => {
  return (
    <section className='FooterContainer'>
        <a className="credit" href='https://iosipratama.com/'>Design inspired by @iosipratama's template</a>
        <div className="greet">Have a nice day 🤘</div>
    </section>
  )
}

export default FooterContainer